import React from 'react';
import PropTypes from 'prop-types';

import { Stage, MainStage } from '@ccg/middleeast';

import PageTemplate from '../templates/page';
import { removeSpecialCharacters } from '../../../helper';

import Embeds from '../../shared/Embeds/Embeds';

const Standard = ({ data }) => {
  const { entry, footer, navigation, seoDefaults, cookieLayer, social } = data;

  // Breadcrumb: Update if breadcrumb needs to suport multiple entries
  const path = entry.uri.split('/');
  const firstEntry = navigation.mainLinks.find(
    link => link.linkTo.type === 'entry' && link.linkTo.element.slug === path[0]
  );

  return (
    <PageTemplate
      navigation={navigation}
      seo={entry}
      footer={footer}
      social={social}
      seoDefaults={seoDefaults}
      cookieLayer={cookieLayer}
    >
      {entry.contentType === 'advertising' ? (
        <MainStage
          headline={entry.headline || entry.title}
          title={removeSpecialCharacters(entry.title)}
          subline={entry.subline}
          excerpt={entry.excerpt}
          image={
            entry.image &&
            entry.image.length > 0 && {
              ...entry.image[0],
              srcset: {
                '1440w': entry.image[0].width1440,
                '960w': entry.image[0].width960,
                '720w': entry.image[0].width720,
                '375w': entry.image[0].width375
              },
              source: {
                '1x': entry.image[0].width400,
                '2x': entry.image[0].width800,
                '3x': entry.image[0].width1200
              }
            }
          }
          video={entry.video && entry.video.length > 0 && entry.video[0]}
          loop={entry.loop}
          breadcrumbLink={
            path.length > 1
              ? {
                  label: removeSpecialCharacters(
                    firstEntry.linkTo.customText || firstEntry.linkTo.element.title
                  ),
                  href: { pathname: '/standard', query: { uri: firstEntry.linkTo.element.uri } },
                  asPath: `/${firstEntry.linkTo.element.uri}`
                }
              : undefined
          }
        />
      ) : (
        <Stage
          image={
            entry.image &&
            entry.image[0] && {
              ...entry.image[0],
              srcset: {
                '1440w': entry.image[0].width1440,
                '960w': entry.image[0].width960,
                '720w': entry.image[0].width720,
                '375w': entry.image[0].width375
              }
            }
          }
          video={entry.video && entry.video.length > 0 && entry.video[0]}
          loop={entry.loop}
          title={removeSpecialCharacters(entry.title)}
          headline={entry.headline || entry.title}
          subline={entry.subline}
          excerpt={entry.excerpt}
          breadcrumbLink={
            path.length > 1
              ? {
                  label: removeSpecialCharacters(
                    firstEntry.linkTo.customText || firstEntry.linkTo.element.title
                  ),
                  href: { pathname: '/standard', query: { uri: firstEntry.linkTo.element.uri } },
                  asPath: `/${firstEntry.linkTo.element.uri}`
                }
              : undefined
          }
        />
      )}

      <Embeds embedsCopy={entry.embedsCopy} embeds={entry.embeds} />
    </PageTemplate>
  );
};

Standard.propTypes = {
  data: PropTypes.shape().isRequired
};

Standard.defaultProps = {};

export default Standard;
